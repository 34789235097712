:root {
  --primary: #2febd2;
  --white: #ffffff;
}

/* body { background-color: var(--primary); } */

* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/open-sans.woff2") format('woff2');
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/rubik.woff2") format('woff2');
} */

body {
  background-color: #eaefef;
  color: #333;
  font-family: "Segoe UI", "Roboto", sans-serif;
  margin: 0;
}

.logo {
  display: block;
  height: 3rem;
  width: auto;
}

.date-bar {
  /* background-color: #2febd2; */
  align-items: center;
  display: flex;
  text-align: center;
}

.date-bar-button {
  background-color: transparent;
  color: #333;
  border: none;
  border-radius: 0;
  padding: 1rem;
  transition: 0.3s;
}

.date-bar-button:hover {
  background-color: var(--primary);
  cursor: pointer;
}

.date-bar-date-container {
  flex: auto;
}

.date-bar-day-of-week {
  color: #888;
  font-size: 0.8rem;
}

main {
  padding: 1rem;
}

.entry + .entry {
  margin-top: 1rem;
}

.entry-input {
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 1rem;
  padding: .5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.entry-input:hover {
  border-color: #000;
  cursor: pointer;
}

.input-label {
  color: #333;
  margin-bottom: 1rem;
  line-height: 1;
  font-weight: bold;
}

h1 {
  line-height: 1;
  margin: 0rem;
}

h2 {
  line-height: 1;
  margin: 0;
  padding: .5rem;
}

p {
  margin: 0 0 1rem 0;
}

.entry-input input {
  border: none;
  font-family: monospace;
  font-size: 3rem;
  line-height: 1;
  padding: 0;
  text-align: center;
  width: 100%;
}

.graph {
  width: 100%;
  height: 250px;
}

.container {
  padding: 1rem;
}

.entry-list {
  padding: 1rem;
}

.entry-list-item {
  padding: .5rem;
  /* border-bottom: 1px solid #e3e3e3; */
  display: flex;
  justify-content: space-between;
  line-height: 1;
}

.entry-list-item:hover {
  background-color: aquamarine;
  border-radius: .5rem;
  cursor: pointer;
}

.entry-list-item:last-of-type {
  border-bottom: none;
}

.tool-tip {
  background-color: #333333ee;
  border-radius: 8px;
  color: #2febd2;
  font-size: 12px;
  padding: .5rem;
  text-align: center;
}

.tool-tip-date {
  font-size: 8px;
  margin-bottom: .25rem;
}

.tool-tip-value {
  font-weight: bold;
}

.icon {
  /* fill: currentColor; */
  width: 1rem;
  height: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: #004aad;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  padding: 0.5rem 1rem;
  position: relative;
  font-size: 0.85rem;
  border: none;
  border-radius: 4px;
  display: flex;
}

.alert-button {
  background-color: #be1f1f;
}

button .icon {
  margin-right: .25rem;
}

.button-group {
  display: flex;
}

.button-group *+* {
  margin-left: .5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
}

.entry-input.checkbox label.checked {
  color: #2febd2;
}

.entry-input.checkbox label.unchecked {
  color: #888;
}

.checkbox-icon {
  height: 3rem;
  width: 3rem;
}

.entry-input.checkbox input {
  display: none;
}

/* .card {
  padding: .5rem;
  border: 2px solid #666;
  border-radius: 1rem;
} */

.card.selected {
  background-color: #d5fdf7;
}

.bg-hover:hover {
  background-color: #d5fdf7;
}

.card-title {
  font-weight: bold;
  margin-bottom: .5rem;
}

.label {
  font-weight: bold;
  margin-bottom: .5rem;
  line-height: 1;
}

.list > *:not(:last-child) {
  margin-bottom: 1rem;
}

.square {
  background-color: #ccc;
  border: 2px solid #ccc;
  border-radius: 1rem;
  height: 50px;
  overflow: hidden;
  width: 50px;
}

.square-image {
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.flex > *:not(:last-child) {
  border-radius: .5rem;
  margin-right: .5rem;
  flex-shrink: 0;
}

.text-field {
  border: 2px solid #333;
  border-radius: 1rem;
  font-weight: bold;
  padding: 1rem;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.mb- {
  margin-bottom: .5rem;
}

img {
  display: block;
  max-width: 100%;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.placeholder-image {
  width: 75%;
  margin: auto;
  max-width: 300px;
}

.placeholder-message {
  text-align: center;
}

.card {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 3px rgba(0,0,0,0.06);
  margin: 1rem;
  padding: .5rem;
}